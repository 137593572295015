











import Day from '@/modules/common/types/day.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import { Inject } from 'inversify-props';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import UserService, { UserServiceS } from '../../../user/user.service';
import AvailableFilterAll from './cards/available-filter-all.vue';
import SoldOut from './cards/sold-out.vue';
import NoData from './cards/no-data.vue';
import NotAvailable from './cards/not-available.vue';

@Component({
    components: {
        NotAvailable,
        NoData,
        SoldOut,
        AvailableFilterAll,
    },
})

export default class RatesCalendarItemFilterAll extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(RatesAllServiceS) ratesAllService!: RatesAllService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    private nonClickableClasses: string[] = ['calendar-item__actions'];

    get isNoData() {
        return this.ratesService.isNoData(this.day);
    }

    get isSoldOut() {
        return !this.ratesService.getAverageRoomsPrice(this.day);
    }

    handleClick(e: MouseEvent) {
        const currentTargetElement = e.currentTarget as HTMLElement;
        const targetElement = e.target as HTMLElement;

        const isNonClickable = (element: HTMLElement): any => {
            if (!element.parentElement) {
                return false;
            }

            if (this.nonClickableClasses.includes(element.parentElement.className)) {
                return true;
            }

            if (element.parentElement!.className === currentTargetElement.className) {
                return false;
            }

            return isNonClickable(element.parentElement);
        };

        if (!isNonClickable(targetElement)) {
            this.$router.push({
                name: `${this.$route.name!}.day-rate`,
                params: { day: String(this.day) },
            });
        }
    }
}
