















import { Component, Vue, Prop } from 'vue-property-decorator';
import Day from '@/modules/common/types/day.type';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';

@Component({
    components: {
        CalendarItemWrapper,
    },
})
export default class CalendarItemNoData extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    private isNA?: boolean;
}
