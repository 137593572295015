




















import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import RatesColorPicker from '@/modules/rates/components/calendar/rates-color-picker.vue';
import RatesCalendar from '@/modules/rates/components/calendar/rates-calendar.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import RatesDemands from '@/modules/rates/components/rates-demands.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import TogglePrices from '@/modules/common/modules/rates/components/toggle-prices.vue';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

@Component({
    components: {
        DateDocumentFilter,
        RatesColorPicker,
        RatesDemands,
        RatesCalendar,
        RatesActions,
        PageWrapper,
        RatesHeader,
        TogglePrices,
    },
})
export default class RatesCalendarPage extends Vue {
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    get disableBtn() {
        return this.ratesFiltersService.isProviderAll || this.ratesFiltersService.storeState.isPriceShownSwitchDisabled;
    }

    get isAllChannelsMode() {
        return this.ratesFiltersService.settings.provider === 'all';
    }
}
