

















import { Component, Vue } from 'vue-property-decorator';
import Demand from '@/modules/common/components/ui-kit/demand.vue';

@Component({
    components: { Demand },
})
export default class RatesDemands extends Vue {
    low = 1;
    lows = [
        { id: 1, name: '1%' },
    ];
    medium = 1;
    mediums = [
        { id: 1, name: '1% - 10%' },
    ];
    high = 1;
    highs = [
        { id: 1, name: '>10%' },
    ];
}
