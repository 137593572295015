








import { Inject } from 'inversify-props';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Day from '@/modules/common/types/day.type';
import BaseCard from './base-card.vue';

@Component({ components: { BaseCard } })
export default class NotAvailable extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;
}
