
import { Component } from 'vue-property-decorator';
import { TableRow } from '../../interfaces/day-rate-tooltip.interface';
import DayRateTooltip from '../day-rate-tooltip.vue';

@Component({
    extends: DayRateTooltip,
})
export default class DayRateTooltipCalendar extends DayRateTooltip {
    mutateRoomData(roomList: TableRow[], competetiveRoom: TableRow) {
        if (this.isAllChannelsMode) return roomList;

        const { currentHotelId } = this.userService;

        const myRoom = roomList
            .find(room => room.hotelId === currentHotelId)!;

        const myRoomIndex = roomList
            .indexOf(myRoom);

        const medianIndex = competetiveRoom
            ? roomList.indexOf(competetiveRoom)
            : myRoomIndex;

        const endCompensation = -Math.min(0, roomList.length - (medianIndex + 3));
        const startCompensation = -Math.min(0, medianIndex - 2);

        const startSlice = Math.max(0, medianIndex - 2 - endCompensation);
        const endSlice = Math.min(roomList.length, medianIndex + 3 + startCompensation);

        const newList = roomList.slice(startSlice, endSlice);

        const isMyRoomInRange = newList.includes(myRoom);

        if (isMyRoomInRange) {
            let roomIndexToAdd = myRoomIndex > medianIndex
                ? endSlice + 1
                : startSlice - 1;

            if (roomIndexToAdd < 0) {
                roomIndexToAdd = endSlice + 1;
            }

            if (roomIndexToAdd > roomList.length - 1) {
                roomIndexToAdd = startSlice - 1;
            }

            if (roomList[roomIndexToAdd]) {
                newList.push(roomList[roomIndexToAdd]);
            }
        } else {
            const arrMethod = myRoomIndex > medianIndex
                ? 'push'
                : 'unshift';

            newList[arrMethod](myRoom);
        }

        return newList;
    }
}
