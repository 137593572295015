


























import { Component, Vue, Prop } from 'vue-property-decorator';
import ScanBtnSmall from './scan-btn-small.vue';

@Component({
    components: {
        ScanBtnSmall,
    },
})
export default class CalendarItemWrapper extends Vue {
    @Prop({
        type: Boolean,
        default: true,
    })
    private hoverable!: boolean;
}
